/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import GlobalStyles from "../styles/GlobalStyles"
import theme from "../styles/theme"
import Header from "./Header"
import Footer from "./Footer"
import withFilters from "../containers/withFilters"
import "../styles/reset.css"

const Layout = ({ children, className = '', filterType }) => {
  const data = useStaticQuery(graphql`
    query {
      site: markdownRemark(frontmatter: { slug: { eq: "general-settings-data"}}){
        frontmatter {
          site_title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <div className={`app-container page-${className} ${filterType ? "has-filter" : ""}`}>
          <Header siteTitle={data.site.frontmatter.site_title} />
          <main className="app-main">
            {children}
          </main>
          <Footer />
        </div>
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withFilters(Layout)
