import { graphql, useStaticQuery } from 'gatsby'
import get from "lodash/get"

const useGeneralSettings = () => {
  const data = useStaticQuery(graphql`
    query {
      site: markdownRemark(frontmatter: { slug: { eq: "general-settings-data"}}){
        frontmatter {
          site_title
          site_description
          site_url
          site_author
          download_report_url
          social {
            og_title
            og_description
            og_image
            og_image_width
            og_image_height
            og_image_type
          }
        }
      }
    }
  `)

  return get(data, "site.frontmatter") || {}
}

export default useGeneralSettings
