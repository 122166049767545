import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import HeaderPageNav from "../components/HeaderPageNav"

const Header = ({ siteTitle }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <HeaderWrapper className={menuOpen ? "active" : ""}>
      <div className="header-container container">
        <div className="header-left">
          <div className="header-title heading main">
            <h1>
              <Link to="/">{siteTitle}</Link>
            </h1>
          </div>
          <div className="header-title heading go-back">
            <Link to="/">
              <span className="arrow" />
              Browse all<span>&nbsp;cities</span>
            </Link>
          </div>
          <div className="explore-buttons">
            <div>
              {/* <Link to="/add-your-city" className="button primary">Add your city</Link> */}
              <Link to="/explore" className="button">
                Explore cities
              </Link>
            </div>
          </div>
          <Link to="/cities" id="search-nav-btn" className="button">
            Search
          </Link>
        </div>
        <HeaderPageNav open={menuOpen} />
        <div className={`burger-container ${menuOpen ? "active" : ""}`}>
          <div
            className="burger-block"
            onClick={() => setMenuOpen(old => !old)}
          >
            <div className="burger-row"></div>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 101;
  background: white;
  box-shadow: 0 0 16px 0 rgba(98, 101, 110, 0.14);

  &.active {
    z-index: 2001;
    .header-left {
      z-index: 2001;
    }
  }

  #search-nav-btn {
    width: auto;
    margin-left: 1rem;
  }

  .burger-container {
    display: none;
    position: absolute;
    top: 0;
    height: 100%;
    right: 3.125rem;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      right: 20px;
    }
    justify-content: center;
    align-items: center;
    z-index: 2001;
    .burger-block {
      position: relative;
      top: -4px;
      width: 22px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      .burger-row {
        position: relative;
        width: 100%;
        height: 2px;
        background-color: black;
        transition: all ease 0.15s;
        &::before,
        &::after {
          display: block;
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: black;
          transition: all ease 0.15s;
        }

        &::before {
          top: -6px;
        }

        &::after {
          bottom: -6px;
        }
      }
    }

    &.active {
      .burger-row {
        background-color: white;
        &::before {
          transform-origin: left top;
          transform: rotate(45deg) translateY(-2px);
        }
        &::after {
          transform-origin: left bottom;
          transform: rotate(-45deg) translateY(2px);
        }
      }
    }
  }

  @media (max-width: 1250px) {
    .burger-container {
      display: flex;
    }
  }

  .header-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 4.75rem;

    @media (min-width: ${({ theme }) => theme.tablet}) {
      height: 4.375rem;
    }

    .header-left {
      display: flex;
      align-items: center;
    }

    .explore-buttons {
      display: none;
      flex-direction: column;
      justify-content: center;
      a:not(:last-child) {
        margin-right: 12px;
      }

      > div {
        display: flex;
      }
    }
  }

  .header-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 0;

    @media (min-width: ${({ theme }) => theme.tablet}) {
      margin-right: 1.5rem;
    }

    @media (min-width: ${({ theme }) => theme.largeDesktop}) {
      margin-right: 3.5rem;
    }

    a,
    h1 {
      font-family: ${({ theme }) => theme.fonts["roboto"]};
      font-weight: 900;
      display: inline-block;
      font-size: 0.75rem;
      color: #252525;
      text-decoration: none;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 1rem;
      }

      @media (min-width: ${({ theme }) => theme.tablet}) {
        letter-spacing: 0.3px;
      }
    }

    &.main {
      display: none;
    }

    &.go-back {
      @media (min-width: ${({ theme }) => theme.mobile}) {
        a {
          padding-left: 1.75rem;
          position: relative;

          &:before {
            position: absolute;
            top: 50%;
            left: 0;
            content: "";
            display: block;
            width: 1rem;
            height: 1rem;
            background: transparent url(/img/arrow-back.svg) no-repeat center
              center;
            background-size: contain;
            transition: transform 0.15s ease;
            transform: translate(0, -50%);
          }

          &:hover {
            &:before {
              transform: translate(0, -50%);
            }
          }
        }
      }

      @media (min-width: ${({ theme }) => theme.mobile}) {
        a {
          font-size: 1rem;
          color: ${({ theme }) => theme.grey1};

          &:hover {
            color: #252525;
          }
        }
      }

      span {
        display: none;

        @media (min-width: ${({ theme }) => theme.tablet}) {
          display: inline-block;
        }
      }
    }
  }

  .page-home &,
  .page-explore & {
    .header-container {
      justify-content: center;
      flex-direction: column;

      @media (min-width: ${({ theme }) => theme.tablet}) {
        justify-content: space-between;
        flex-direction: row;
      }
    }

    .header-title {
      display: flex;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        height: auto;
      }

      &.go-back {
        display: none;
      }
    }
  }

  .page-home & {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 18rem;
    background: white;
    transition: all 0.15s;

    @media (min-width: ${({ theme }) => theme.mobile}) {
      height: 15rem;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      position: fixed;
      height: 8.75rem;

      body.is-scrolled & {
        height: 4.375rem;
      }
    }

    .header-container {
      position: relative;
      height: 4.375rem;
      transition: all 0.15s;

      @media (min-width: ${({ theme }) => theme.desktop}) {
        height: 8.75rem;
      }

      body.is-scrolled & {
        height: 4.375rem;
      }
    }

    .explore-buttons {
      @media (min-width: ${({ theme }) => theme.desktop}) {
        display: none;
        opacity: 0;
        transition: opacity 0.15s 0.15s ease;

        body.map-engaged &,
        body.is-scrolled & {
          display: flex;
          opacity: 1;
        }
      }
    }
  }

  .page-explore & {
    .explore-buttons {
      @media (min-width: ${({ theme }) => theme.desktop}) {
        display: flex;
      }
    }
  }
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
