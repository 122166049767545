import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import get from "lodash/get"
import ReactMarkdown from "react-markdown"
import CustomLink from "../components/CustomLink"

export const FooterTemplate = ({
  text,
  nav = [],
  copyright,
  legal_links = []
}) => (
    <FooterWrapper>
      <div className="upper">
        <div className="container">
          <div className="columns">
            <div className="column column-3 menu-column">
              <div className="nav-list">
                <nav>
                  <ul>
                    {nav.map((item, index) => (
                      <li key={`footer-nav-item-${index}`}>
                        <CustomLink href={item.href}>
                          <p>{item.text}</p>
                        </CustomLink>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="column column-6 text-column">
              <div className="footer-text content">
                <ReactMarkdown source={text} />
              </div>
            </div>
            <div className="column column-3 logo-column">
              <a href="https://www.bloomberg.org/" target="_blank" rel="noreferrer noopener">
                  <img src="/img/BloombergPhilanthropies.png" alt="Bloomberg Philanthropies Logo" style={{width: 112, height: "auto"}} />
              </a>
              <a href="https://www.oecd.org" target="_blank" rel="noreferrer noopener">
                <img src="/img/OECD.png" alt="OECD Logo" style={{width: 112, height: "auto"}}/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="lower">
        <div className="container">
          <div className="lower-inner">
            <p>© {new Date().getFullYear()}, {copyright}</p>
            <p>
              {legal_links.map((item, index) => (
                <span key={`legal-link-${index}`}>
                  {index > 0 && (<span>&nbsp;|&nbsp;</span>)}
                  <a href={item.href}>{item.text}</a>
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
    </FooterWrapper>
  )

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { slug: { eq: "footer-data"}}){
        frontmatter {
          text
          nav {
            text
            href
          }
          copyright
          legal_links{
            text
            href
          }
        }
      }
    }
  `);
  const {
    text,
    nav = [],
    copyright,
    legal_links = []
  } = get(data, 'markdownRemark.frontmatter')
  // return <FooterTemplate text={text} nav={nav} copyright={copyright} legal_links={legal_links} />
  return <FooterTemplate text={text} nav={nav.filter(e => e.href !== "/add-your-city")} copyright={copyright} legal_links={legal_links} />
}

const FooterWrapper = styled.footer`
  .menu-column {
    order: 2;
  }
  .text-column {
    order: 1;
  }
  .logo-column {
    order: 3;
    display: flex;

    > * {
      display: block;
      text-decoration: none;
      margin-right: 1.25rem;
      margin-bottom: 1rem;
    }
  }
  @media (min-width: ${({ theme }) => theme.tablet}) {
    .menu-column {
      order: 2;
    }
    .text-column {
      order: 1;
    }
    .logo-column {
      order: 3;
      display: block;
    }
  }

  .nav-list {
    a {
      display: inline-block;
      text-decoration: none;

      &:hover {
        text-decoration: underline;

        &, * {
          color: black;
        }
      }
    }
  }

  .footer-text {
    @media (min-width: ${({ theme }) => theme.largeDesktop}) {
      padding-right: 5rem;
    }

    p {
      font-family: ${({ theme }) => theme.fonts["vollkorn"]};
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: normal;
      color: ${({ theme }) => theme.grey2};

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 1.25rem;
        line-height: 1.6;
        letter-spacing: normal;
      }

    }
  }

  .upper {
    padding: 3rem 0 0;

    @media (min-width: ${({ theme }) => theme.mobile}) {
      padding: 3.125rem 0;
    }

    p {
      a {
        text-decoration: underline;
        text-underline-offset: 0.2rem;

        &:hover {
          color: black;
        }
      }
    }
  }

  .lower {
    background: #f6f6f6;
    padding: 1.75rem 0;

    p {
      line-height: 1;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .lower-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    p:first-child {
      margin-bottom: 0.625rem;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      flex-direction: row;
      p:first-child {
        margin-bottom: 0;
      }
    }
  }
`

export default Footer;
