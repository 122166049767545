import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  html {
    font-family: ${({ theme }) => theme.fonts["vollkorn"]};
    font-size: 16px;
    line-height: 1.4;
    background: #f4f4f4;
  }

  .relative {
    position: relative;
  }

  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100.25vh;
    width: 100%;
    margin: 0 auto;
    background: white;
    box-shadow: 0 0 16px 0 rgba(98, 101, 110, 0.14);


    & > main{
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    body.is-ie & {
      display: block;
        & > main{
        display: block;
      }
    }
  }

  .container {
    padding: 0 20px;
    margin: 0 auto;
    max-width: 80rem;

    &.mobile-full-bleed {
      margin-left: -20px;
      margin-right: -20px;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (min-width: ${({ theme }) => theme.mobile}) {
      padding: 0 3.125rem;
    }
  }

  .inner-container {
    max-width: 63.75rem;
    margin: 0 auto;
  }

  .columns {
    display: flex;
    margin: 0 -0.625rem;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.tablet}) {
      flex-direction: row;
    }

    body.is-ie & {
      display: block;
      @media (min-width: ${({ theme }) => theme.tablet}) {
        display: flex;
      }
    }

    .column {
      flex: 1;
      padding: 0 0.625rem;
      margin-bottom: 1.25rem;
      flex-basis: 100%;

      @media (max-width: ${({ theme }) => theme.mobile}) {
        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (min-width: ${({ theme }) => theme.tablet}) {

        &.column-1 {
          flex: 0 0 100%;
        }
        &.column-2 {
          flex: 1 0 calc(100% / 12 * 2);
          max-width: calc(75em / 12 * 2);
        }
        &.column-3 {
          flex: 1 0 calc(100% / 12 * 3);
          max-width: calc(75em / 12 * 3);
        }
        &.column-4 {
          flex: 1 0 calc(100% / 12 * 4);
          max-width: calc(75em / 12 * 4);
        }
        &.column-5 {
          flex: 1 0 calc(100% / 12 * 5);
          max-width: calc(75em / 12 * 5);
        }
        &.column-6 {
          flex: 1 0 calc(100% / 12 * 6);
          max-width: calc(75em / 12 * 6);
        }
        &.column-7 {
          flex: 1 0 calc(100% / 12 * 7);
          max-width: calc(75em / 12 * 7);
        }
        &.column-8 {
          flex: 1 0 calc(100% / 12 * 8);
          max-width: calc(75em / 12 * 8);
        }
        &.column-9 {
          flex: 1 0 calc(100% / 12 * 9);
          max-width: calc(75em / 12 * 9);
        }
        &.column-10 {
          flex: 1 0 calc(100% / 12 * 10);
          max-width: calc(75em / 12 * 10);
        }
        &.column-11 {
          flex: 0 0 calc(100% / 12 * 11);
          max-width: calc(75em / 12 * 11);
        }
        &.column-12 {
          flex: 0 0 calc(100% / 12 * 12);
          max-width: calc(75em / 12 * 12);
        }
      }
    }

    &.no-gutter {
      margin: 0;

      .column {
        padding: 0;
      }
    }

    &.centered {
      justify-content: center;
      text-align: center;
    }
  }

  body, html {
    h1, h2, h3, h4, h5, h6, .heading {
      font-family: ${({ theme }) => theme.fonts["roboto-condensed"]};
      line-height: 1.2;
      font-weight: bold;
      color: ${({ theme }) => theme.grey1};
    }

    h1 {
      font-size: 2rem;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 2.5rem;
      }
    }

    h2 {
      font-size: 1.25rem;
      line-height: 1.25;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 2rem;
      }

    }

    h3 {
      font-size: 1rem;
      line-height: 1.75;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 1.5rem;
        line-height: 1.17;
      }
    }

    h4 {
      font-size: 1.125rem;
      line-height: 1.33;
      letter-spacing: 0.34px;
    }

    h5 {
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.34px;
      color: ${({ theme }) => theme.dark};

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 1.125rem;
        line-height: 1.33;
      }
    }

    p, ul li, ul, ol, ol li {
      font-family: ${({ theme }) => theme.fonts["roboto"]};
      font-size: 0.875rem;
      color: ${({ theme }) => theme.grey2};
      line-height: 1.71;
      letter-spacing: 0.35px;

      &.large {
        font-family: ${({ theme }) => theme.fonts["vollkorn"]};
        font-size: 1.25rem;
        line-height: 1.6;
        letter-spacing: normal;
      }

      &.x-large {
        font-family: ${({ theme }) => theme.fonts["vollkorn"]};
        font-size: 1.875rem;
        line-height: 1.33;
        letter-spacing: -0.2px;
      }

      strong {
        font-weight: bold;
      }
      em {
        font-style: italic;
      }

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    .large {
      &, p, ul li, ul, ol, ol li {
        font-family: ${({ theme }) => theme.fonts["vollkorn"]};
        font-size: 1.25rem;
        line-height: 1.6;
        letter-spacing: normal;

        strong {
          font-weight: bold;
        }
        em {
          font-style: italic;
        }
      }
    }

    .x-large {
      &, p, ul li, ul, ol, ol li {
        font-family: ${({ theme }) => theme.fonts["vollkorn"]};
        font-size: 1.875rem;
        line-height: 1.33;
        letter-spacing: -0.2px;
      }
    }

    .content {
      h1, h2, h3, h4, h5, h6, .heading, p, p.large {
        margin: 1rem 0;

        @media (min-width: ${({ theme }) => theme.mobile}) {
          margin: 1.25rem 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          margin-top: 0;
        }

        &.font-white {
          color: white;
        }
      }

      blockquote {
        font-family: ${({ theme }) => theme.fonts["vollkorn"]};
        font-size: 1.25rem;
        line-height: 1.6;
        letter-spacing: normal;
      }

      ul {
        list-style: disc;
        margin-left: 2em;
      }

      ol {
        list-style: decimal;
        margin-left: 2em;
      }
    }

    .button {
      display: inline-flex;
      width: 100%;
      height: 2.5rem;
      flex-direction: column;
      justify-content: center;
      padding: 0 1rem;
      border: 2px solid ${({ theme }) => theme.grey1};
      color: ${({ theme }) => theme.grey1};
      font-family: ${({ theme }) => theme.fonts["roboto"]};
      font-weight: bold;
      font-size: 0.75rem;
      line-height: 1;
      letter-spacing: 0.5px;
      border-radius: 1.25rem;
      text-align: center;
      white-space: nowrap;
      text-decoration: none;
      cursor: pointer;
      transition: all 0.15s;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        width: auto;
        margin: 0 0.3125rem;
      }

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 0.875rem
        padding: 0 1.25rem;

      }

      &:hover {
        background: ${({ theme }) => theme.grey1};
        color: white;
      }

      &.grey, &.dark, &.solid, &.primary {
        background: ${({ theme }) => theme.darkBlue};
        color: white;
        border-color: ${({ theme }) => theme.darkBlue};

        &:hover {
          background: transparent;
          color: ${({ theme }) => theme.darkBlue};
        }
      }
    }

    .grid {
      display: grid;

      &.grid-1 {
        grid-template-columns: 1fr;
        grid-gap: 1.5rem;
        margin-bottom: 1.5rem;
      }

      &.grid-2 {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.5rem;
        margin-bottom: 1.5rem;
      }
      &.grid-3 {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1.875rem;
        margin-bottom: 1.875rem;
      }
      &.grid-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 1.125rem;
        margin-bottom: 1.125rem;
      }
    }

    .card {
      display: block;
      border-radius: 12px;
      padding: 2.5rem 3.33%;
      box-shadow: 0px 2px 10px 0 rgba(98, 101, 110, 0.06);
      background: white;

      &:hover, &:focus, &:active {
        box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.10);
      }

      @media (max-width: ${({ theme }) => theme.mobile}) {
        margin-left: -20px;
        margin-right: -20px;
        padding: 1.25rem 20px;
        border-radius: 0;
        box-shadow: none;
      }
    }
  }

  .chart-placeholder {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid lightgrey;
    background: lightgrey;

    &, * {
      color: black !important;
    }

    &:before {
      content: 'placeholder for chart/graph/list';
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    &:after {
      content: '';
      width: 100%;
      display: block;
      height: 0;
      padding-top: 56.89%;
    }
    &.square {
      &:after {
        padding-top: 100%;
      }
    }
  }
`
