import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import useGeneralSettings from "../hooks/use-general-settings"

function SEO({ description, lang, meta, title }) {

  const {
    site_title,
    site_description,
    site_url,
    site_author,
    social = {}
  } = useGeneralSettings()

  const {
    og_title,
    og_description,
    og_image,
    og_image_width,
    og_image_height,
    og_image_type
  } = social

  const metaDescription = description || site_description
  const pageTitle = title ? `${title} | ${site_title}` : site_title
  const ogPageTitle = title ? `${title} | ${og_title}` : og_title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${ogPageTitle}`,
        },
        {
          property: `og:site_name`,
          content: `${og_title}`,
        },
        {
          property: `og:description`,
          content: `${og_description || metaDescription}`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${og_image}`,
        },
        {
          property: `og:image:type`,
          content: `${og_image_type}`,
        },
        {
          property: `og:image:width`,
          content: `${og_image_width}`,
        },
        {
          property: `og:image:height`,
          content: `${og_image_height}`,
        },
        {
          property: `og:url`,
          content: `${site_url}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image`,
          content: `${og_image}`,
        },
        {
          name: `twitter:creator`,
          content: site_author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
