import React from 'react'
import { Location } from '@reach/router'
import queryString from 'query-string'

const withFilters = ComponentToWrap => props => (
  <Location>
    {({ location, navigate }) => {
      const search = location.search ? queryString.parse(location.search) : {}
      return (
        <ComponentToWrap
          {...props}
          location={location}
          navigate={navigate}
          search={search}
          filter={search.filter}
          filterType={search.filterType}
        />
      )
    }}
  </Location>
)

export default withFilters
