import React from "react"
import styled from "styled-components"
import SocialIcon from "./styled-components/SocialIcon"
import useGeneralSettings from "../hooks/use-general-settings"

const SocialShare = () => {
  const {
    site_url,
    site_author,
    social = {}
  } = useGeneralSettings()
  const { og_description } = social
  const url = encodeURIComponent(site_url)
  const description = encodeURIComponent(og_description)
  const descriptionWithUrl = encodeURIComponent(`${og_description} ${site_url}`)
  const author = encodeURIComponent(site_author)
  return (
    <Wrapper>
      <div className="button button--share" tabIndex="0">
        <div>
          <span className="share__text">Share</span>
          <div className="icons">
            <a
              href="/"
              title="Click to share on Facebook"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault()
                window.open(`http://www.facebook.com/sharer.php?u=${url}`, "ShareFacebook", getNewWindowParameters())
                return false
              }}
            >
              <SocialIcon className="facebook" />
            </a>
            <a
              href="/"
              title="Click to share on LinkedIn"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault()
                window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&summary=${description}&source=${author}`, "ShareLinkedIn", getNewWindowParameters())
                return false
              }}
            >
              <SocialIcon className="linkedin" />
            </a>
            <a
              href="/"
              title="Click to share on Twitter"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault()
                window.open(`https://twitter.com/intent/tweet?url="${url}&text=${descriptionWithUrl}`, "ShareTwitter", getNewWindowParameters())
                return false
              }}
            >
              <SocialIcon className="twitter" />
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`

  * {
    vertical-align: middle;
  }
  .button {
    width: auto;
    height: 2.25rem;
    padding: 0 0.75rem;
    background: white;

    @media (min-width: ${({ theme }) => theme.mobile}) {
      padding: 0 1.25rem;
    }

    .icons {
      max-width: 0;
      overflow: hidden;
      display: inline-block;
      margin-left: 0;
      transition: all 0.1s 0.15s ease;

      .social-icon {
        opacity: 0;
        transition: opacity 0.15s ease;

        &:nth-child(1) {
          transition-delay: 0.1s;
        }
        &:nth-child(2) {
          transition-delay: 0.05s;
        }
        &:nth-child(3) {
          transition-delay: 0.0s;
        }
      }
    }

    &:hover, &:focus {
      color: ${({ theme }) => theme.grey1};
      background: white;

      @media (max-width: ${({ theme }) => theme.mobile}) {
        box-shadow: 0 0 16px 0 rgba(98, 101, 110, 0.3);
      }

      .icons {
        max-width: 102px;
        transition: all 0.15s ease;
        margin-left: 0.625rem;

        .social-icon {
          opacity: 1;
          transition: opacity 0.15s ease;

          &:nth-child(1) {
            transition-delay: 0.15s;
          }
          &:nth-child(2) {
            transition-delay: 0.2s;
          }
          &:nth-child(3) {
            transition-delay: 0.25s;
          }
        }
      }
    }
  }
`

const getNewWindowParameters = () => {
  let left = 0
  let top = 0
  const width = 570
  const height = 570
  if (typeof window !== "undefined") {
    left = (window.screen.width - width) / 2;
    top = (window.screen.height - height) / 2;
  }

  return `menubar=no,toolbar=no,status=no,width=${width},height=${height},top=${top},left=${left}`
}

export default SocialShare