import { css } from 'styled-components'

const theme = {
  dark: "#1d212b",
  grey1: "#62656e",
  grey2: "#414141",
  grey3: "#e4e5e6",
  offWhite: '#f9f9f9',
  darkBlue: '#014485',
  lightBlue: '#5a889f',
  silver: '#edeeef',
  purple: '#88317a',
  greenBlue: '#00838f',
  pink: '#ef446d',
  graphGreen: '#70b785',
  graphRed: '#de5356',
  fonts: {
    "roboto": "'Roboto', Arial, sans-serif",
    "roboto-condensed": "'Roboto Condensed', Arial, sans-serif",
    "vollkorn": "'Vollkorn', 'Times New Roman', serif"
  },
  mobile: '599px',
  tablet: '799px',
  desktop: '999px',
  largeDesktop: '1179px',
  mobileSmall: '375px',
  hideBreaks: () => css`
    br {
      @media (min-width: ${theme.tablet}) and (max-width: ${theme.desktop}) {
        display: none;
      }
    }
  `,
  isHeadingSize: {
    '1': css`
      font-size: 2rem;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 2.5rem;
      }
    `,
    '2': css`
      font-size: 1.25rem;
      line-height: 1.25;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 2rem;
      }
    `,
    '3': css`
      font-size: 1rem;
      line-height: 1.75;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 1.5rem;
        line-height: 1.17;
      }
    `,
    '4': css`
      font-size: 1rem;
      line-height: 1.5;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 1.125rem;
        line-height: 1.33;
        letter-spacing: 0.34px;
      }
    `,
    '5': css`
      font-family: ${({ theme }) => theme.fonts["roboto-condensed"]};
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.34px;
      font-weight: bold;
      color: ${({ theme }) => theme.dark};

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 1.125rem;
        line-height: 1.33;
      }
    `
  }
}

export default theme
