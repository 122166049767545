import styled from "styled-components"

const SocialIcon = styled.div`
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.grey1};
  margin-left: 0.625rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 70%;
  cursor: pointer;

  &.facebook {
    background-image: url(/img/facebook.svg);
    background-size: auto 70%;
  }

  &.linkedin {
    background-image: url(/img/linkedin.svg);
    background-size: 60% auto;
  }

  &.twitter {
    background-image: url(/img/twitter.svg);
    background-size: 60% auto;
  }
`

export default SocialIcon
