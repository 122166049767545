import React from "react"
import { Link } from "gatsby"

const CustomLink = ({
  className = '',
  href = '',
  target = '',
  title = '',
  children
}) => (
  <>
    {href.startsWith('http') 
      ? <a href={href} className={className} target={target || "_blank"} title={title} rel="noopener noreferrer">{children}</a>
      : <Link to={href} className={className} title={title}>{ children }</Link>
    }
  </>
)

export default CustomLink