import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import SocialShare from "./SocialShare"

const HeaderNav = ({ open }) => {
  return (
    <Wrapper aria-label="Site Navigation" className={open ? "open" : ""}>
      <ul>
        <li>
          <Link
            className="header-nav-item heading"
            activeClassName="header-nav-item--active"
            to="/about/"
          >
            About
          </Link>
        </li>

        <li>
          <Link
            className="header-nav-item heading"
            activeClassName="header-nav-item--active"
            to="/methodology/"
          >
            Methodology
          </Link>
        </li>
        <li>
          <div tabIndex={0} className="header-nav-item heading menu">
            <div className="title">Well-being</div>
            <ul className="content" tabIndex={0}>
              <li>
                <Link
                  className="header-nav-item heading"
                  activeClassName="header-nav-item--active"
                  tabIndex={0}
                  to="/outcomes/"
                >
                  Outcomes
                </Link>
              </li>
              <li>
                <Link
                  className="header-nav-item heading"
                  activeClassName="header-nav-item--active"
                  tabIndex={0}
                  to="/how-to-act"
                >
                  How to act
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <Link
            className="header-nav-item heading"
            activeClassName="header-nav-item--active"
            to="/read-the-report/"
          >
            Read the Report
          </Link>
        </li>
        <li>
          <Link
            className="header-nav-item heading"
            activeClassName="header-nav-item--active"
            to="/contact/"
          >
            Contact
          </Link>
        </li>
        <li className="share">
          <SocialShare />
        </li>
      </ul>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 4.5rem;
  transition: all ease 0.3s;

  @media (min-width: ${({ theme }) => theme.mobile}) {
    margin-right: 6rem;
  }

  @media (min-width: ${({ theme }) => theme.tablet}) {
    margin-right: 0;
  }

  ul {
    display: flex;

    li:first-child {
      .header-nav-item {
        margin-left: 0;
      }
    }

    .share {
      display: flex;
      align-items: center;
    }
    /* .share {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);

      * {
        vertical-align: middle;
      }

      @media (min-width: ${({ theme }) => theme.mobile}) {
        right: 40px;
      }

      @media (min-width: ${({ theme }) => theme.tablet}) {
        position: static;
        top: auto;
        right: auto;
        transform: none;

        .button {
          height: 2.5rem;
        }
      }
    } */
  }

  .header-nav-item {
    display: inline-block;
    font-family: ${({ theme }) => theme.fonts["roboto"]};
    font-weight: 900;
    font-size: 0.75rem;
    letter-spacing: 0;
    text-decoration: none;
    padding: 0;
    margin: 0 0.25rem;
    border: 2px solid transparent;
    transition: all 0.15s;

    @media (min-width: ${({ theme }) => theme.mobile}) {
      margin: 0 0.3125rem;
      font-size: 0.875rem;
    }

    @media (min-width: ${({ theme }) => theme.mobile}) {
      margin: 0 0.874rem;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      letter-spacing: 0.3px;
      padding: 0.75rem 0;
    }

    &--active {
      color: #252525;
    }

    &:hover {
      color: #252525;
      cursor: pointer;
    }

    &.menu {
      position: relative;
      .content {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        min-width: max(100%, 8rem);
        transform: translateY(100%);

        background-color: white;
        border-radius: 6px;

        padding: 0.5rem 1rem;
        flex-direction: column;

        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.51);
        row-gap: 8px;

        &::before {
          display: block;
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          top: -6px;
          left: 1rem;
          background-color: white;
          transform: rotate(45deg);
          z-index: -1;
        }

        li {
          a {
            text-decoration: none;
            padding: 0;

            transition: color ease 0.25s;
            &:hover {
              color: black;
            }
          }
        }
      }
    }

    &.menu.active .content,
    &.menu:hover .content,
    &.menu:focus .content,
    &.menu .content:focus,
    &.menu .content:focus-within,
    &.menu .content:hover {
      display: flex;
      animation-name: opacityOn;
      animation-duration: 0.1s;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;

      li {
        animation-name: opacityOn;
        animation-duration: 0.15s;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
        opacity: 0;
        &:nth-child(1) {
          animation-delay: 0.1s;
        }
        &:nth-child(2) {
          animation-delay: 0.15s;
        }
      }
    }
    @keyframes opacityOn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  @media (max-width: 1250px) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    padding-top: 4.35rem;
    width: 100%;
    transform: translateY(-100%);
    background-color: white;
    z-index: 2000;
    opacity: 0;

    justify-content: flex-start;

    ul {
      flex-direction: column;
      align-items: center;
    }

    &.open {
      transform: none;
      opacity: 1;
      a {
        margin: 0;
        padding: 0.75rem !important;
      }

      .share {
        a {
          padding: 0 !important;
        }
      }

      .menu {
        margin: 0;
        padding: 0;
        animation: none !important;
        .title {
          display: none;
        }
        .content {
          &::before {
            all: unset;
          }
          all: unset;
          display: flex;
          animation: none !important;
          flex-direction: column;
          row-gap: 0;
          li {
            animation: none !important;
            opacity: 1 !important;
          }
        }
      }
    }
  }
`

export default HeaderNav
